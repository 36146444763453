import { EpaycoService } from './../../core/services/epayco.service';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { PaymentConfigModel } from '../../core/models/payment-config.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ScriptUtility } from '../../core/utilities/script.utility';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { AuthModel } from 'src/app/core/models/auth.model';
import { CardsService } from 'src/app/core/services/cards.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import Swal from 'sweetalert2';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { PostMessageService } from '../../core/services/post-message.service';
import { HostListener } from '@angular/core';
import { IdleService } from '../../core/services/idle.service';

const HOSTNAME = 'localhost';

@Component({
  selector: 'app-payment-request',
  templateUrl: './payment-request.component.html',
  styleUrls: ['./payment-request.component.scss'],
})
export class PaymentRequestComponent implements OnInit {
  @ViewChild('swalCreditPayment') private swalCreditPayment: SwalComponent;

  @ViewChild('swalBankTransactionPayment')
  private swalBankTransactionPayment: SwalComponent;

  paymentRequest: PaymentConfigModel;
  styleUrl: SafeResourceUrl;
  loadCss = false;
  css: string;
  googleTagManager = 'GTM-N7F7TW7';
  cards: any = [];
  enabledPaymentMethods: {
    pse: boolean;
    card: boolean;
  };
  puntosColombia: boolean = false;
  puntosColombiaPromotion: any;
  tokenaEnviar: any;
  backToCartUrl: boolean = false;
  tokenPm: string;
  constructor(
    private _router: Router,
    private sanitizer: DomSanitizer,
    private sessionStorage: SessionStorageService,
    private epaycoService: EpaycoService,
    private cardsService: CardsService,
    private datalayerService: DatalayerService,
    private loadingScreenService: LoadingScreenService,
    private idleService: IdleService,
  ) {
    this.tokenaEnviar = this.sessionStorage.getItem<AuthModel>(
      SessionStorageService.AUTH,
    );
    if (
      this._router.getCurrentNavigation().extras === undefined ||
      this._router.getCurrentNavigation().extras.state === undefined ||
      this._router.getCurrentNavigation().extras.state.obj === undefined
    ) {
      if (window.location.hostname === HOSTNAME) {
        this.#reloadPaymentScreen().then();
        return;
      }

      this._router.navigate(['/pagos/mensaje'], {
        state: { message: 'Se ha presentado un error con esta solicitud' },
      });

      return;
    }

    this.googleTagManager = this.sessionStorage.getItem(
      SessionStorageService.GOOGLE_TAG_MANAGER,
    );

    this.paymentRequest = this._router.getCurrentNavigation().extras.state.obj;
    this.paymentRequest.data.name = this.paymentRequest.data.name.replace(
      /\s\s+/g,
      ' ',
    );
    this.paymentRequest.data.last_name =
      this.paymentRequest.data.last_name.replace(/\s\s+/g, ' ');

    ScriptUtility.setGoogleTagManager(this.googleTagManager);
    ScriptUtility.setGoogleTagManagerNoScript(this.googleTagManager);
    this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentRequest.url_style,
    );
    this.loadCss = true;

    if (this.paymentRequest.gateway_active === 'ePayco') {
      const data = this.paymentRequest.data;
      data.client = this.paymentRequest.name;
      const auth = this.sessionStorage.getItem<AuthModel>(
        SessionStorageService.AUTH,
      );
      this.epaycoService.getCustomerCards(auth.token, data).subscribe(
        (success) => {
          this.cards = Object.values(success);
          this.cardsService.emitConfig(this.cards);
        },
        (failure) => {
          console.log(failure);
        },
      );
    }

    if (this.paymentRequest.has_puntoscolombia) {
      this.puntosColombia = true;
      this.puntosColombiaPromotion =
        this.paymentRequest.colombia_points_promotion;
    }
    this.idleService.activateIdle(
      this.paymentRequest.idleTime.idle,
      this.paymentRequest.idleTime.timeout,
    );
  }

  getUrlPayment() {
    return this.sessionStorage.getItem(SessionStorageService.PAYMENT_URL);
  }

  ngOnInit() {
    this.loadPaymentMethods();
    this.datalayerService.addInfoDataLayerVirtualPagePaymentMethod();
    this.backToCartUrl =
      this.paymentRequest.back_commerce.includes(this.paymentRequest.name) ||
      this.paymentRequest.data.origen == 'carrito';
  }

  loadPaymentMethods() {
    this.enabledPaymentMethods = {
      card: false,
      pse: false,
    };

    const gateway = this.paymentRequest.gateway[0].payment_method;

    gateway.forEach((method) => {
      if (method.code === 'CARD') {
        this.enabledPaymentMethods.card = true;
      }
      if (method.code === 'BANK_TRANSACTION') {
        this.enabledPaymentMethods.pse = true;
      }
    });
  }

  backToCommerce() {
    this.loadingScreenService.startLoading();
    const auth = this.sessionStorage.getItem<AuthModel>(
      SessionStorageService.AUTH,
    );

    const body = {
      client_token: this.paymentRequest.data.client_token,
      dev_reference: this.paymentRequest.data.reference,
    };

    this.datalayerService.addBackToCommerceDataLayer(
      this.paymentRequest.data.client,
    );

    origin = this.paymentRequest.data.origen ?? '';
    this.epaycoService
      .backToCommerce(
        auth.token,
        this.paymentRequest.data.client_token,
        this.paymentRequest.data.reference,
        this.paymentRequest.data.url_return,
        this.paymentRequest.name,
        origin,
      )
      .subscribe((response: any) => {
        if (
          response.status == 'ok' &&
          (response.code == 200 || response.code == 201)
        ) {
          const url = response.result;
          this.loadingScreenService.stopLoading();
          window.location.replace(url.returnGatewayUrl);
        }
        if (response.status == 'error') {
          location.reload();
        }
        this.loadingScreenService.stopLoading();
      });
  }

  @HostListener('click', ['$event'])
  @HostListener('wheel', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('mousemove', ['$event'])
  onEvent(event: Event) {
    if (this.idleService.getIsIdle()) {
      this.idleService.sendPostMessage();
      this.idleService.IdleWatch();
    }
  }

  #reloadPaymentScreen = async () => {
    const url = this.getUrlPayment() as string;
    const cipher = url.split('=')[1];

    return await this._router.navigate(['/pagos/solicita'], {
      queryParams: { q: cipher },
    });
  }
}
