import { inject, Injectable } from '@angular/core';

import { environment as env } from '@environment/environment';
import { TransactionDataService } from '@payment-app/core/services/transaction-data';
import { CdnLoaderService, JQUERY350, NUVEI_CHECKOUT } from '@core/services/cdn-loader';
import { NuveiCardReferenceRequest, NuveiCardResponse } from './interfaces';
import { NuveiCardService } from './nuvei-card.service';

declare const PaymentCheckout: any;

@Injectable({
  providedIn: 'root',
})
export class NuveiCardCheckoutService {
  readonly #cdnLoaderService = inject(CdnLoaderService);
  readonly #nuveiCardService = inject(NuveiCardService);
  readonly #transactionDataService = inject(TransactionDataService);
  readonly #transactionData = this.#transactionDataService.transactionData;

  constructor() {
    this.#cdnLoaderService.load(JQUERY350, NUVEI_CHECKOUT).then();
  }

  openModalCheckout(isCreditCard: boolean): void {
    const paymentCheckoutModal = new PaymentCheckout.modal({
      env_mode: env.paymentezEnviromentMode,
      onOpen: this.onOpen,
      onClose: this.onClose,
      onResponse: this.onResponse,
    });

    const nuveiCardReferenceRequest: NuveiCardReferenceRequest = {
      amount: this.#transactionData.transaction.amount,
      description: this.#transactionData.transaction.description,
      vat: this.#transactionData.transaction.vat,
      reference: this.#transactionData.transaction.reference,
      userId: this.#transactionData.customerData.idValue,
      name: this.#transactionData.customerData.name,
      email: this.#transactionData.customerData.email,
      installments: isCreditCard ? 0 : -1,
    };

    this.#nuveiCardService.getReference(nuveiCardReferenceRequest).subscribe({
      next: (response) => {
        paymentCheckoutModal.open(response);
      },
    });

    window.addEventListener('popstate', () => {
      paymentCheckoutModal.close();
    });
  }

  private onOpen = () => {};
  private onClose = () => {};
  private onResponse = (response: NuveiCardResponse) => {
    const transaction = response.transaction;
    transaction.franchise = response.card.type;
    transaction.authorization_code = response.transaction.authorization_code ?? '000000';
   
    const _transaction = this.#transactionData.transaction;
   
    this.#nuveiCardService.postUpdateNuveiTransaction(transaction).subscribe({
      next: (_) => (window.location.href = _transaction.responseUrl),
      error: (_) => (window.location.href = _transaction.responseUrl),
    });
  };
}
