import {EventEmitter, inject, Injectable, Output} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

import { environment } from '@environment/environment';
import { PaymentTransactionModel } from '../models/paymentez/payment-transaction.model';
import { SubclientResponse } from 'src/app/report-transactions/report-transactions.component';
import {EncryptService} from "@core/services/encrypt.service";

@Injectable({
  providedIn: 'root',
})
export class PaymentTransactionService {
  @Output() consultTransactionByPortal: EventEmitter<any> = new EventEmitter();
  encryptService = inject(EncryptService);

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  postTransaction(
    transaction: PaymentTransactionModel,
    token: string,
  ): Observable<string> {
    const body = {
      data: this.encryptService.encryptData(transaction, false),
    };

    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    return this.http.post<string>(environment.apiUrl + '/api/request-pay', body, {
      headers,
    });
  }

  postTransactionTokenize(request, token: string): Observable<string> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post<string>(environment.apiUrl + '/api/tokenize-card', request, {
      headers,
    });
  }

  // request:{id_transaction:string}
  checkTransactionStatus(request, token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    return this.http.post(
      environment.apiUrl + '/api/load/consult-status-detail',
      request,
      { headers },
    );
  }

  checkTransactionStatusForNequi(request, token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    return this.http.post(
      environment.apiUrl + '/api/load/consult-status-detail-nequi',
      request,
      { headers },
    );
  }

  reportTransactions(request, token: string, page?) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    let url = environment.apiUrl + '/api/report-transactions';

    if (page) {
      url += `?page=${page}`;
    }

    return this.http.post(url, request, { headers });
  }

  reportTransactionsExcel(request, token) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    const url = environment.apiUrl + '/api/download-client-excel';

    return this.http.post(url, request, { responseType: 'blob', headers });
  }

  reviewTransactions(request, token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    const url = environment.apiUrl + '/api/consult-total';

    return this.http.post(url, request, { headers });
  }

  getTransactionTotalsByMonths(request, token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    const url = environment.apiUrl + '/api/consult-total-month';

    return this.http.post(url, request, { headers });
  }

  public getSubclients(request, token: string): Observable<SubclientResponse> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    const url = environment.apiUrl + '/api/subtextclients';

    return this.http.post<any>(url, request, { headers });
  }

  public generatePaylink(request, token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    const url = environment.apiUrl + '/api/generate-link-manual';

    return this.http.post<any>(url, request, { headers });
  }

  public validateMaskCard(token: string, request: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post(environment.apiUrl + '/api/verify-token-card', request, {
      headers,
    });
  }

  public consumeBonusBines(request, token) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('Content-Type', 'application/json');
    const url = environment.apiUrl + '/api/consult-bonus';

    return this.http.post<any>(url, request, { headers });
  }

  public displayBonusSnack(message) {
    this.snackBar.open(message, null, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public returnCommerce(token, request) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('Content-Type', 'application/json');

    const url = environment.apiUrl + '/api/return-commerce';

    return this.http.post<any>(url, request, { headers });
  }

  checkGeneralAuditTransaction(params, token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    return this.http.get(
      environment.apiUrl + '/api/reports/epayco-gateway-general-audit-response',
      { params, headers },
    );
  }

  reviewTransactionsByPoral(params, token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    const url = environment.apiUrl + '/api/consult-total-by-portal';

    return this.http.get(url, { params, headers });
  }
}
